.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex-grow: 1;
}

.App-link {
  color: #61dafb;
}

.toplist {
  background-color: #282c34;
  color: white;
  min-height: 100vh;

}

.eloDiff {
  animation: myanim 1s cubic-bezier(.4,0,.2,1) forwards;
  position: absolute;
  margin-left: -100px;
}

@keyframes myanim {
  from{
    transform: translateX(107%);
  }
  38% {
    opacity: 1;
  }
  42% {    
    transform: translateX(35%);
  }
  60% {
    opacity: 0;
    transform: translateX(35%);
  }
  62% {
    transform: translateX(0);
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}